@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap");

.lang-select {
    padding-top: 16px;
    padding-bottom: 16px;
    /* margin: 2% 2%; */
}

.table-row {
    padding-block: 10px;
}

.lang-ddown {
    padding: 0.2% 3% 0.2% 0%;
}

.inst-deets {
    /* margin: 0% 2%; */
    overflow-y: auto;
}

.inst-points {
    padding: 0.5% 0% 0.5% 3%;
}

.inner-heading-secondInst {
    margin-top: 50px;
}

.inst-inner {
}

.marks-symbol {
    border: 0.8px solid #909090;
    border-radius: 10px;
    padding: 12px 24px;
}

.marks-symbol.disclaimer {
    height: 100%;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.instructions-row {
    display: flex;
}
 
@media screen and (max-width: 1199px) {
    .instructions-row {
        display: block;
    }
}

@media screen and (max-width: 1199px) {
    .marks-symbol.disclaimer {
        margin-top: 10px;
    }
}

.marks-symbol.disclaimer strong {
    font-size: 18px;
    font-weight: 600;
}

.visited {
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.next-btn-container {
    /* width: 100%; */
    /* padding: 1%;
    margin: 2%; */
    border: 0.6px solid #909090;
    /* position: absolute;
    bottom: 0; */
}

@media screen and (max-width: 767px) {
    .next-btn-container {
        width: 100%;
    }
}

.laptop-right {
    text-align: right;
}

.laptop-left {
    text-align: left;
}

.next-btn-inst {
    padding: 1%;
    background-color: #ffffff;
    margin-right: 3%;
    border: 0.6px solid #909090;
}

.innerInst-second {
    border: 0.7px solid #000000;
    width: 75%;
    padding: 5px;
}

.inner-list-inst-second {
    border: 0.7px solid #000000;
    text-align: left;
}

.inner-list-inst-second {
    padding-left: 26px;
}

.red-color {
    color: #ff1f1f;
}

.not-visited-div {
    background: linear-gradient(123.24deg, #c4c4c4 0%, rgba(196, 196, 196, 0.43) 123.1%);
    border: 1px solid #adadad;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25), inset 3px 4px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 5px 9px;
    margin: 5px;
    text-align: center;
    color: #706f6f;
    font-weight: 500;
    height: 30px;
    width: 35px;
}

.not-answered-active-div {
    background-image: url(../../../images/genericV2/not-ans-active.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    color: #999999;
    padding: 5px 9px;
    margin: 5px;
    height: 30px;
    width: 35px;
}

.answered-active-div {
    background-image: url(../../../images/genericV2/ans-active.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    color: #ffffff;
    padding: 5px 9px;
    margin: 5px;
    height: 30px;
    width: 35px;
}

.not-ans-mkd-active-div {
    background-image: url(../../../images/genericV2/na-mkd-rev-active.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    color: #999999;
    padding: 5px 9px;
    margin: 5px;
    height: 30px;
    width: 35px;
}

.ans-mkd-active-div {
    background-image: url(../../../images/genericV2/ans-mkd-rev-active.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    color: #ffffff;
    padding: 5px 9px;
    margin: 5px;
    height: 30px;
    width: 35px;
}

.nav-bar {
    height: 75px;
}

.container.genericV2-instructions {
    height: calc(100vh - 75px);
    position: relative;
}

.genericV2-instructions-loading {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.genericV2-instructions-loading-graphic {
    width: 154px;
    height: 154px;
    border-radius: 50%;
    overflow: hidden;
}

.genericV2-instructions-ready {
    width: 190px;
}

.genericV2-instructions-loading-graphic img, .genericV2-instructions-ready img {
    width: 100%;
    height: auto;
}

.genericV2-instructions-percentage {
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.genericV2-instructions-p1 {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    max-width: 370px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.genericV2-instructions-p2 {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    max-width: 370px;
}

.genericV2-instructions-btn {
    background: #1f3061;
    color: #fff;
    height: 61px;
    width: 100%;
    max-width: 248px;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
}

.genericV2-instructions-page {
    height: calc(100% - 100px);
}

.genericV2-instructions-buttons {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
}

.genericV2-instructions-buttons .next-btn-container {
    height: 64px;
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.genericV2-instructions-lang {
    height: 74.53px;
}

.genericV2-instructions-title {
    height: 22px;
}

.genericV2-instructions-details {
    height: calc(100% - 74.53px - 22px);
}

/* TABLET */
@media (max-width: 767.98px) {
    .innerInst-second {
        width: 85%;
    }
}

/* MOBILE */
@media (max-width: 575.98px) {
    .genericV2-instructions-loading-graphic {
        width: 120px;
        height: 120px;
    }
    
    .genericV2-instructions-ready {
        width: 127px;
    }

    .genericV2-instructions-p1 { 
        font-size: 20px;
    }

    .genericV2-instructions-btn {
        max-width: 207px;
        width: 100%;
    }

    .lang-select {
        /* margin: 7% 2%; */
    }

    .next-btn-container {
        /* padding: 3% 2%;
        margin: 2%; */
        border: 0.6px solid #909090;
    }

    .inst-points {
        padding: 1% 1% 1% 7%;
    }

    .innerInst-second {
        width: 90%;
    }

    .laptop-right {
        text-align: center;
    }

    .laptop-left {
        text-align: center;
    }
}
